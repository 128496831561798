<template>
  <div v-loading="loading" class="table-area wrapper-trading">
    <div  class="table-top">
    <el-row>
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <h4 class="heading heading-active">Accounts</h4>
      </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <div class="search-wrapper">
            <!-- <el-input
              placeholder="Search by broker"
              v-model="listQuery.search"
              class="input-with-select"
              style="width:500px;"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="handleSearch()"
              ></el-button>
            </el-input> -->

            
          </div>
        </el-col>
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="btn-wrapper">
          <el-button  type="primary" @click="handleDownload()">Download</el-button>
        </div>
      </el-col>
    </el-row>
    </div>

    <el-row style="margin-bottom: 0px !important">
      <el-col :xs="24" :lg="24" :xl="24">
        <div class="box-cell">
          <div class="box-single-cell">
            
            <div class="box-cell-content">
              <h1>Broker</h1>
              <h3>{{ listData.broker }}</h3>
            </div>
          </div>

          <div class="box-single-cell">
            
            <div class="box-cell-content">
              <h1>Payable/Receivable</h1>
              <h3>{{ listData.receivable_payable }}</h3>
            </div>
          </div>

          <div class="box-single-cell">
            
            <div class="box-cell-content">
              <h1>Sum of Client P/L</h1>
              <h3>{{ listData.sum_client_pl }}</h3>
            </div>
          </div>

          <div class="box-single-cell">
            
            <div class="box-cell-content">
              <h1>Sum of Client Brokerage</h1>
              <h3>{{ listData.sum_client_brokerage }}</h3>
            </div>
          </div>

          <div class="box-single-cell">
           
            <div class="box-cell-content">
               <h1>Sum of Client Net</h1>
              <h3>{{ listData.sum_client_net }}</h3>
            </div>
          </div>

          <div class="box-single-cell">
           
            <div class="box-cell-content">
               <h1>P/L Share</h1>
              <h3>{{ listData.pl_share }}</h3>
            </div>
          </div>

          <div class="box-single-cell">
           
            <div class="box-cell-content">
               <h1>Brokerage Share</h1>
              <h3>{{ listData.brokerage_share }}</h3>
            </div>
          </div>

          <div class="box-single-cell">
           
           <div class="box-cell-content">
              <h1>Brokerage Charge</h1>
             <h3>{{ listData.brokerage_charge }}</h3>
           </div>
         </div>

          <div class="box-single-cell">
           
            <div class="box-cell-content">
               <h1>Net Share</h1>
              <h3>{{ listData.net_share }}</h3>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { fetchListForBroker, downloadAcountList } from "@/api/account";

export default {
  name: "ListClosePositions",
  components: {
  },
  data() {
    return {
      loading: false,
      config: [
        {
          label: "Receivable / Payable",
          prop: "receivable_payable",
          className: "blueFont",
          width: "200"
        },
        {
          label: "Broker",
          prop: "broker",
          className: "blueFont",
          width: "150"

        },
        {
          label: "SUM of Client PL",
          prop: "sum_client_pl",
          className: "blueFont",
          width: "200"
        },
        {
          label: "SUM of Client Brokerage",
          prop: "sum_client_brokerage",
          width: "240",
          className: "blueFont",
        },
        {
          label: "SUM of Client Net",
          prop: "sum_client_net",
          className: "blueFont",
          width: "200"

        },
        {
          label: "PL Share",
          prop: "pl_share",
          className: "blueFont",
          width: "150"

        },
        {
          label: "Brokerage Share",
          prop: "brokerage_share",
          className: "blueFont",
          width: "200"

        },
        {
          label: "Net Share",
          prop: "net_share",
          className: "blueFont",
          width: "200"

        },
      ],
      listData: {},
      listQuery: {
        page: 1,
        limit: 20,
        search: "",
        searchJoin: "or",
        orderBy: "created_at",
        sortedBy: "desc",
        start_date: null,
        end_date: null,
      },
      data: {
        data: {
          item: [
            {
              receivable_payable: "Rs.0 Receive from Parent Admin.",
              broker: "31: superadmin",
              sum_client_pl: "82302",
              sum_client_brokerage: "19559.13",
              sum_client_net: "62742.87",
              pl_share: "0",
              brokerage_share: "0",
              net_share: "0",
            },
          ],
        },
      },
    };
  },
  computed: {},
  created() {
    this.getList();
  },
  methods: {
    // //////////////////
    // GET LIST DATA
    // //////////////////
    handlePagination() {
      this.getList();
    },
    handleSearch() {
      this.listQuery.page = 1;
      this.getList();
    },
    // getList() {
    //   this.listData = this.data;
    //   console.log(this.listData);
    // },
    getList() {
      this.loading = true;
      fetchListForBroker(this.listQuery).then((response) => {
        this.listData = response.data.data;

        this.loading = false;
      });
    },
    // on date range update
    onUpdatedExpireDate(value) {
      this.listQuery.start_date = value.start;
      this.listQuery.end_date = value.end;
    },
    handleDownload() {
      downloadAcountList(this.listQuery).then((response)=> {
        console.log(response.data.data)
        let res = response.data.data
        this.download(res.fileName,res.path)
      })
      .catch((err) => {
        console.log(err);
      });
    },
    
    download(filename, url) {
          var xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          // xhr.setRequestHeader('Content-type','application/x-www-form-urlencoded');
          xhr.onload = () => {
              var blob = xhr.response;
              var downloaded = document.createElement('a');
              var downloadedurl = window.URL.createObjectURL(blob);
              downloaded.href = downloadedurl;
              downloaded.download = filename;
              document.body.append(downloaded);
              downloaded.click();
              downloaded.remove();
              window.URL.revokeObjectURL(downloadedurl);
          };
          xhr.open('GET', url);
          xhr.send();
    },
  },
};
</script>
